import React, { useState } from 'react'
import data from "./output.json"
export default function Finder() {
    const [acc,setAcc] = useState("")
    const [find,setFind] = useState([])
  return (
    <div style={{display:"flex",flexDirection:"column"}} >
      <div style={{display:"flex",justifyContent:"center",}}>
        <input value={acc} placeholder="..." type='number' onChange={(e)=>{
        setFind([])
        setAcc(e.target.value)
      }} />
      <button onClick={()=>{
        if(acc !==""){
            data.map(d=>{
                if(d.accNum == acc){
                    setFind(e=>[...e , d])
                  
                  
                }
            })
        }
      }}> شماره حساب </button>
      <button onClick={()=>{
        
        if(acc !==""){
            data.map(d=>{
                if(d.email == acc){
                  setFind(e=>[...e , d]) 
                }
            })
        }
      }}> ایمیل </button>
      <button onClick={()=>{
        if(acc !==""){
            data.map(d=>{
                if(d.phone == acc){
                setFind(e=>[...e , d])
                }
            })
        }
      }}> شماره </button>
      </div>
      <div style={{textAlign:"center"}}>
       {find.map((d,i)=>{
         return <div key={i} style={{border:"1px solid black" , padding:"10px",
         borderRadius:"10px" , margin:"20px"}}>  
            <div> {"نام :  "+d.name} </div>
            <div> {"شماره :  "+d.phone} </div>
            <div> {"ایمیل :  "+d.email} </div>
            <div> {"حساب :  "+d.accNum} </div>
         </div>
       })}
      </div>
    </div>
  )
}
