import axios from 'axios';
import React, { useState } from 'react';

function CategoryInput({ addCategory  }) {
  const [categoryName, setCategoryName] = useState('');
  const [catDep, setCapDep] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (categoryName.trim() === '') return;
    if(categoryName !== ""){
      
    setCategoryName('');
    axios.post("https://crm.fenefx.net/api/quick/store" , {title: categoryName,department_id:catDep}).then(res=>{
      console.log(res.data);
      addCategory(res.data );
    }).catch(err=>{
      console.log(err);
      
    })
    }
  };

  return (
    <form style={{width:"100%",display:"flex"}} onSubmit={handleSubmit}>
      <input
        type="text"
        value={categoryName}
        style={{width:"50%"}}
        onChange={(e) => setCategoryName(e.target.value)}
        placeholder="Enter category name"
      />
      <select
        type="text"
        value={catDep}
        style={{width:"30%"}}
        onChange={(e) => setCapDep(e.target.value)}
        placeholder="Enter category name"
      >
        <option value={1}> پشتیبانی </option>
        <option value={2}> فنی </option>
        <option value={3}> عمومی </option>
      </select>
      <button style={{width:"20%"}} type="submit">Add Category</button>
    </form>
  );
}

export default CategoryInput;
