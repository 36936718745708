/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CategoryInput from './CategoryInput';
import { FaRegEdit } from "react-icons/fa";
import axios from 'axios';
function CategoryList({ categories , addCategory , role , catUp , di , back }) {
  const [edit,setEdit] = useState("")
  const [ed,setEd] = useState("")
  const cat = ()=>{
     
    if(di === 0){
      return <div className='catList'>
                 <div className='each' >
                     <h2 >
                        <div onClick={()=>{
                          back(1)
                          
                      }}> پشتیبانی </div>
                      </h2>
                  </div>
                  <div className='each' >
                     <h2 >
                        <div onClick={()=>{
                          back(2)
                      }}> فنی </div>
                      </h2>
                  </div>
                  <div className='each' >
                     <h2 >
                        <div onClick={()=>{
                          back(3)
                      }}> عمومی </div>
                      </h2>
                  </div>
             </div>
    }else{
      return <>
      <div style={{
         padding:"10px" , 
         background:"green",cursor:"pointer" ,
         color:"white",width:"200px"}} 
         onClick={()=>{
          back(0)
      }}> Back To Categories </div>
      <div className='catList'>
        {categories.map(category => 
          {if(category.department_id == di){
            return <div className='each' key={category.id}>
            <h2 >
            {edit!==category.id?<><FaRegEdit onClick={()=>{
                 setEdit(category.id)
              }} style={{verticalAlign:"middle" , marginRight:"10px"}} />
              <Link style={{
                  direction:"rtl"
                 }} to={`/category/${category.title}?id=${category.id}`}>
              {category.title}
              </Link>
              </>:
              <>
                 <button onClick={()=>{
                  setEdit("")
                 }}>cancel</button>
                 <input value={ed} onChange={(e)=>{
                  setEd(e.target.value)
                 }} type='text' style={{
                  direction:"rtl",
                  textAlign:"right"
                 }} placeholder={category.title}/>
                 <button onClick={()=>{
                  if(ed !== "" ){
                    axios.post("https://crm.fenefx.net/api/quick/"+category.id+"/update" , {
                     title: ed
                    }).then(res=>{
                      catUp()
                      
                    }).then(()=>{
                      setEd("")
                      setEdit("")
                    })
                    .catch(err=>{
                      console.log(err);
                      
                    })
                  }else{
                    alert("متن Category خالی می باشد")
                  }
                 }}>edit</button>
              </>
              }
            </h2>
          </div>
          }}
        )}
      </div>
    </>
    }
  }
  return (
    <>
    <h1> Category Message App </h1>
    {role==="admin"? <CategoryInput addCategory={addCategory} />:null}
    {cat()}
    </>
  );
}

export default CategoryList;
